<template>
  <div style='height: 100%'>
    <el-table
      v-loading='loading'
      ref='multipleTableRef'
      :data='originRoomList'
      border
      style='width: 100%'
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa'
      }"
    >
      <el-table-column prop='certificateNo' label='权证号' />
      <el-table-column prop='location' label='房屋坐落' />
      <el-table-column prop='businseeNo' label='业务编号' />
      <el-table-column prop='businessStatus' label='业务状态'>
        <template #default='{ row }'>
          <el-tag
            v-if="row.businessStatus === '审核'"
            type='danger'
            size='mini'
          >
            {{ getBusiNessStatus(row.businessType) }}{{ row.businessStatus }}
          </el-tag>
          <span v-else
          >{{ getBusiNessStatus(row.businessType)
            }}{{ row.businessStatus }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop='createTime' label='业务时间' />

      <el-table-column label='操作' width='120'>
        <template #default='scope'>
          <el-button
            type='text'
            size='small'
            class='list-btn'
            v-if="
              scope.row.businessStatus &&
              (scope.row.businessStatus.indexOf('申请')!=-1 ||
                scope.row.businessStatus.indexOf('审核')!=-1)
            "
            @click='cancelApplyHandel(scope.row)'
          >撤销办理
          </el-button>
          <el-button
            type='text'
            size='small'
            class='list-btn'
            v-if="scope.row.businessStatus && scope.row.businessStatus.indexOf('申请')!=-1 "
            @click='subleaseChangeHandel(scope.row)'
          >变更信息
          </el-button>

          <el-button
            type='text'
            size='small'
            class='list-btn'
            v-if='scope.row.backReason'
            @click='showReasonHandel(scope.row)'
          >查看原因
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout='total, prev, pager, next'
      :total='total'
      :page-size='10'
      :current-page='pageNum'
      style='text-align: right; margin: 10px 0'
    />
  </div>
  <action-modal ref='actionModalRef' @success='getRoomLists'></action-modal>
</template>

<script setup>
import { onMounted, ref, defineProps, defineExpose } from 'vue'
import { getSplitStatusListApi, cancelSubleaseApply, getSubleaseInfoApi } from '@/api/user-house'
import { useRoute, useRouter } from 'vue-router'
import { SPLIT_ACTION_ADD, SPLIT_ACTION_EDIT, SPLIT_ACTION_DEL } from '@/constant'
import { ElMessageBox } from 'element-plus'
import ActionModal from './action-modal'

const props = defineProps({
  houseNumber: { type: String, default: null }
})
const getBusiNessStatus = (type) => {
  const map = {
    1: '注销',
    0: '变更',
    2: '拆分'
  }
  return type ? map[type] : '变更'
}

const route = useRoute()
const router = useRouter()
const actionModalRef = ref()
const loading = ref(false)
const originRoomList = ref([])
// 注解参数
const total = ref(0)
const tableData = ref([])

const add = () => {
  actionModalRef.value.open({ type: SPLIT_ACTION_ADD })
}

const pageNum = ref(1)

// 测试数据，后面要换成房源列表入口

const getRoomLists = async () => {
  try {
    loading.value = true
    const query = {
      type: 1,
      pageNo: pageNum.value
    }
    if (props.houseNumber) {
      query.houseNumber = props.houseNumber
    }
    const resp = await getSplitStatusListApi(query)
    originRoomList.value = resp.records
    total.value = resp.total
  } finally {
    loading.value = false
  }
}

const reloadList = () => {
  pageNum.value = 1
  getRoomLists()
}
// 撤销申请
const cancelApplyHandel = (row) => {
  ElMessageBox.confirm('是否撤销该业务？', '操作提示', {
    confirmButtonText: '确认撤销',
    cancelButtonText: '关闭',
    type: 'warning'
  })
    .then(async () => {
      await cancelSubleaseApply(row.businessId)
      getRoomLists()
    })
    .catch((e) => {
      console.log('操作返回结果', e)
    })
}
// 信息变更
const subleaseChangeHandel = async (row) => {
  try {
    loading.value = true
    const response = await getSubleaseInfoApi(row.businseeNo)
    if (response.records && response.records.length) {
      console.log(response)
      const rows = response.records.map(item => {
        const room = {
          parentRoomNum: item.parentRoomNum,
          id: item.subleaseHouseNum
            ? item.subleaseHouseNum
            : new Date().getTime() + '' + Math.random() * 100, //  生成一个时间序号用来做临时的ID便于后期的变更修改
          roomName: item.roomName,
          roomNum: item.roomNum,
          roomSpace: item.roomSpace,
          roomType: item.roomType,
          changeFlag: item.subleaseType ? item.subleaseType : 0
        }
        return room
      })
      // 2 新建拆分，0变更,1注销，
      const type = row.businessType === 2 ? SPLIT_ACTION_ADD : SPLIT_ACTION_EDIT
      actionModalRef.value.open({ type, rows, statusInfo: row, minioUrl: response.minioUrl })
    } else {
      // 提示找不到业务数据
    }
  } finally {
    loading.value = false
  }

  // 缺少业务数据无法做功能
  // router.push()
}
// 查看错误
const showReasonHandel = (row) => {
  ElMessageBox.alert(row.backReason, '提示', {
    confirmButtonText: '确认',
    cancelButtonText: '关闭',
    type: 'info'
  })
}
onMounted(() => {
  getRoomLists()
})
defineExpose({
  reloadList
})
</script>

<style lang='scss' scoped>
::v-deep .el-dialog__body {
  padding: 0 20px;
}

.tip-wrapper {
  color: #ff9900;
  background: #fdf6ec;
  padding: 10px;
  border-radius: 5px;

  .title {
    font-size: 14px;
  }

  .content {
    margin-top: 5px;
    font-size: 13px;

    p {
      padding: 3px 0;
    }
  }
}
</style>

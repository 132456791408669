<template>
  <el-dialog v-model='addFormDialogVisible' width='40%' title='拆分房源'>
    <el-form
      :model='addForm'
      :rules='addFormRules'
      ref='addFromRef'
      :close-on-click-modal='false'
    >
      <el-form-item label='待拆房间' prop='parentRoomNum'>
        <el-cascader
          :options='rentSplitList'
          v-model='addForm.parentRoomNum'
          :props='cascaderProps'
          clearable
          placeholder='请选择待拆房间'
          style='width: 100%'
        />
      </el-form-item>
      <el-form-item label='房间名称' prop='roomName'>
        <el-input v-model='addForm.roomName' placeholder='请填写房间名称' />
      </el-form-item>
      <el-form-item label='房间面积' prop='roomSpace'>
        <el-input
          v-model='addForm.roomSpace'
          placeholder='请填写房间面积'
          type='number'
        >
          <template #append>㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item label='房间类型' prop='roomType'>
        <el-select
          v-model='addForm.roomType'
          placeholder='请选择房间类型'
          style='width: 100%'
        >
          <el-option label='层' value='层' />
          <el-option label='套' value='套' />
          <el-option label='间' value='间' />
        </el-select>
      </el-form-item>
    </el-form>
    <div class='tip-wrapper'>
      <div class='title'>
        <i class='el-icon-warning-outline mr-2'></i>
        关于房源拆分中涉及的部位名称说明：
      </div>
      <div class='content'>
        <p>
          1.
          对栋拆分，按”层”来排层号的，请统一将部位名称输入为：”一层”、”二层”、”三层”...
        </p>
        <p>
          2.
          对层拆分，按”套”来排房号的，请统一将部位名称输入为：”一层”的输入为”101号房”、”102号房”、”103号房”，”二层”的输入为”201号房”、”202号房”、”203号房”...
        </p>
        <p>
          3.
          对套拆分，按”间”来排室号的，请统一将部位名称输入为：”1号室”、”2号室”、”3号室”...
        </p>
        <p>4. 请遵照房源户型进行拆分, 三室两厅房屋最多只能拆三间房进行租赁。</p>
        <p style='color: #fa3534; margin-top: 10px'>
          房源拆分提示：房源拆分请谨慎拆分，房间拆分后任何变更修改都需要走人工审核，由后台人工处理。
        </p>
      </div>
    </div>
    <template #footer>
      <div class='dialog-footer'>
        <el-button @click='addFormDialogVisible = false'>取消</el-button>
        <el-button type='primary' @click='addSubmitForm'>确认</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
// 新增拆分表单
import {
  defineExpose,
  reactive,
  ref,
  inject,
  computed,
  defineEmits,
  defineProps,
  unref
} from 'vue'
import { getRentBwSelectOptions } from '@/utils/split'
import { ElMessage } from 'element-plus'
import { getSubleaseHouse, getHouseById } from '@/api/user-house'

const emit = defineEmits(['change'])
const props = defineProps({
  addRoomList: { type: Array, default: () => [] },
  houseNumber: { type: String, default: () => '' },
  statusInfo: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const addFormDialogVisible = ref(false)
const addForm = reactive({
  parentRoomNum: [],
  roomName: '',
  roomSpace: '',
  roomType: '',
  roomNum: '',
  id: ''
})
const addFormRules = reactive({
  // parentRoomNum: [
  //   { required: true, message: '待拆房间不能为空', trigger: 'blur' }
  // ],
  roomName: [{ required: true, message: '房间名称不能为空', trigger: 'blur' }],
  roomSpace: [{ required: true, message: '房间面积不能为空', trigger: 'blur' }],
  roomType: [{ required: true, message: '房间类型不能为空', trigger: 'blur' }]
})
// 已拆分列表
const originRoomList = inject('roomList', [])
// 查询拆分列表
const sublist = ref([])

const house = inject('houseInfo', {})

// 树状结构数据
const rentSplitList = computed(() => {
  if (
    (originRoomList.value && originRoomList.value.length) ||
    (sublist.value && sublist.value.length)
  ) {
    let oRoomList = props.houseNumber ? sublist.value : originRoomList.value
    oRoomList = oRoomList.filter((item) => item.roomType != '间')

    let rooms = getRentBwSelectOptions(
      oRoomList,
      props.houseNumber ? props.houseNumber : house.value.lesseeRoomNumber,
      house.value ? house.value.type : null
    )
    const disabledChange = (list = []) => {
      return list.map((item) => {
        item.disabled = false
        if (item.children && item.children.length) {
          item.children = disabledChange(item.children)
        } else {
          item.children || delete item.children
        }
        return item
      })
    }
    rooms = disabledChange(rooms)
    console.log(rooms)
    return rooms
  }

  return []
})

const resetAddForm = () => {
  addForm.parentRoomNum = ''
  addForm.roomName = ''
  addForm.roomNum = ''
  addForm.roomSpace = ''
  addForm.roomType = ''
  addForm.id = ''
}

const cascaderProps = ref({
  checkStrictly: true
})

const addFromRef = ref(null)
const addSubmitForm = () => {
  addFromRef.value.validate((valid) => {
    if (!valid) return
    // var addForm = form.val('roomObj')
    /* 当前选择的父节点ID */
    let parentRoomNum = ''
    if (addForm.parentRoomNum && addForm.parentRoomNum.length) {
      const lastIndex = addForm.parentRoomNum.length - 1
      const lastItem = addForm.parentRoomNum[lastIndex]
      parentRoomNum = lastItem.split('|')[0]
    }

    const roomData = props.houseNumber ? sublist.value : originRoomList.value
    // 变更限制条件，找出所有兄节点
    const sublingList = roomData.filter(
      (item) =>
        item.parentRoomNum === parentRoomNum && addForm.roomNum !== item.roomNum
    )
    // 1.同一层级的兄弟节点名字不能相同
    if (
      sublingList.length &&
      sublingList.filter((item) => item.roomName === addForm.roomName).length
    ) {
      ElMessage.error('同一层级，该房间名称已使用，请重新确认.')
      return false
    }
    // 新增同级兄弟名称互斥
    const newSublingList = unref(props.addRoomList).filter(
      (item) =>
        item.parentRoomNum === parentRoomNum && (addForm.roomNum !== item.roomNum || !item.roomNum)
    )
    // 1.同一层级的兄弟节点名字不能相同
    if (
      newSublingList.length &&
      newSublingList.filter((item) => item.roomName === addForm.roomName).length
    ) {
      ElMessage.error('同一层级，该房间名称已使用，请重新确认。')
      return false
    }

    // 2.变更的面积最小不能低于5平方
    if (Number(addForm.roomSpace) < 5) {
      ElMessage.error('该房间变更面积不能低于5平方米，请重新确认')
      return false
    }
    // 3.变更的节点面积不能超出父节点的剩余面积，既父节点面积-兄弟节点总面积=剩余面积
    if (sublingList.length) {
      const sublingAllSpace = sublingList.reduce((pre, item) => {
        pre += Number(item.roomSpace)
        return pre
      }, 0)
      let parentAllSpace = 0
      let parentNodes = []
      if (parentRoomNum) {
        parentNodes = roomData.filter((item) => item.roomNum === parentRoomNum)
        parentAllSpace = parentNodes[0].roomSpace
      } else {
        // parentNodes = roomData.filter(
        //   (item) => item.parentRoomNum === addForm.parentRoomNum
        // )
        parentAllSpace = 0
        if (house && house.value) {
          parentAllSpace = house.value.buildingArea
        } else {
          parentAllSpace = unref(props.statusInfo).buildingArea
        }
      }
      if (
        Number(parentAllSpace) - Number(sublingAllSpace) <
        Number(addForm.roomSpace)
      ) {
        ElMessage.error(
          '该房间变更面积大于剩余面积[' +
          (Number(parentAllSpace) - Number(sublingAllSpace)) +
          ']平方米，请重新确认'
        )
        return false
      }
    } else {
      const parentNode = roomData.filter(
        (item) => item.roomNum === parentRoomNum
      )
      if (parentNode && parentNode.length) {
        if (Number(addForm.roomSpace) > Number(parentNode[0].roomSpace)) {
          ElMessage.error(
            '该房间变更面积大于剩余面积[' +
            parentNode[0].roomSpace +
            ']平方米，请重新确认'
          )
          return false
        }
      } else {
        if (Number(addForm.roomSpace) > Number(house.value.buildingArea)) {
          ElMessage.error(
            '该房间变更面积大于房屋总面积[' +
            house.value.buildingArea +
            ']平方米，请重新确认'
          )
          return false
        }
      }
    }

    const room = {
      parentRoomNum,
      id: addForm.id
        ? addForm.id
        : new Date().getTime() + '' + Math.random() * 100, //  生成一个时间序号用来做临时的ID便于后期的变更修改
      roomName: addForm.roomName,
      roomNum: addForm.roomNum,
      roomSpace: addForm.roomSpace,
      roomType: addForm.roomType
    }

    ElMessage.success('操作成功！')
    addFormDialogVisible.value = false
    emit('change', { room, isEdit: isEdit.value })
  })
}
/**
 * 是否编辑模式
 * @type {Ref<UnwrapRef<boolean>>}
 */
const isEdit = ref(false)

const open = () => {
  addFormDialogVisible.value = true
  isEdit.value = false
  resetAddForm()
}

const fetchSubList = async () => {
  const resp = await getSubleaseHouse({
    houseNum: props.houseNumber,
    parentRoomNum: ''
  })
  sublist.value = resp.subleaseEntitys
}

const edit = async (row) => {
  addFormDialogVisible.value = true
  if (props.houseNumber) {
    await fetchSubList()
  }
  // ==========重新组装选择框的值：开始============
  const roomData = props.houseNumber ? sublist.value : originRoomList.value
  const parentArrayVal = []
  const findParentValue = (parentNum) => {
    const parent = roomData.filter((item) => item.roomNum === parentNum)
    if (parent && parent.length) {
      parentArrayVal.push(
        `${parent[0].roomNum}|${parent[0].roomSpace}|${parent[0].roomType}|${parent[0].roomName}`
      )
      parent[0].parentRoomNum && findParentValue(parent[0].parentRoomNum)
    }
  }
  if (row.parentRoomNum) {
    findParentValue(row.parentRoomNum)
  }
  // ==========重新组装选择框的值：结束============
  addForm.parentRoomNum = parentArrayVal.reverse()
  addForm.roomName = row.roomName
  addForm.roomSpace = row.roomSpace
  addForm.roomNum = row.roomNum ? row.roomNum : ''
  addForm.roomType = row.roomType
  addForm.id = row.roomNum ? row.roomNum : row.id
  isEdit.value = true
}
defineExpose({
  open,
  edit
})
</script>

<style scoped></style>

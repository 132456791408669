// 房源拆分公共方法

/**
 * 获取整租分租选项框，拆分只用数据是有手动拆分，上架，网签三个位置使用，
 * 其中如果不需要originUpRoomList， 房源ID不要送
 * @param {Array} originRoomList 未处理的监管系统拆分原始数据，只有一份原始数据，可以对应多个分租部位（第四个参数）
 * @param {Array} originUpRoomList 本系统已上架房源部位，存的是房源编号，可能传空数组
 * @param {String} subHouseNum 拆分部位，做分租部位上架或者托管时，后台新增一条房源，此房源编号是没有变，新增了分租三项信息，
 * 此时对于整个房源备案来说是已经有分租上架或者备案，不能进行整租，
 * @param {String} iszz 是否转租，调用源使用sublet字段判断是否是转租
 * @returns
 */
const getRentSelectOptions = (
  originRroomList,
  originUpRoomList,
  subHouseNum,
  iszz
) => {
  // 整租是否被禁用
  let allRentDisable = false
  // 分租是否被禁用
  let splitRentDisable = false
  // 整租上架标志
  let upHouseFlag = false
  // 是否转租
  // const iszz = !![9, 12, 13, 14].find((item) => item === type);

  if (originRroomList.length) {
    let option = null
    if (!iszz) {
      // occupancyType: 0-未占用，其他-已占用；subleaseType：0-未转租，其他-已转租
      // 非转租情况下，查询是否有已被占用或者被转租的部位，如果有则不能整租
      option = originRroomList.find(
        (item) =>
          item.occupancyType === '1' ||
          item.occupancyType === '2' ||
          item.subleaseType === '1'
      )
    } else {
      // 在转租情况下，occupancyType属性是在在占用状态的，所以排除改条件
      option = originRroomList.find((item) => item.subleaseType === '1')
    }
    if (option || subHouseNum) {
      allRentDisable = true
    }
  } else {
    // 没有拆分数据情况下，只能整租，不能有分租（禁用）
    splitRentDisable = true
  }

  // 判断是不是有整租上架的(另外一套判断规则，优先覆盖前一种判断规则)
  if (originUpRoomList && originUpRoomList.length) {
    upHouseFlag = false
    for (const item of originUpRoomList) {
      if (iszz && item.rentType === 1 && item.sublet === 1) {
        allRentDisable = true
        splitRentDisable = true
        break
      } else if (!iszz && item.rentType === 1 && item.sublet === 0) {
        allRentDisable = true
        splitRentDisable = true
        break
      } else if (iszz && item.rentType === 2 && item.sublet === 1) {
        // 判断是分租
        allRentDisable = true
        break
      } else if (!iszz && item.rentType === 2 && item.sublet === 0) {
        allRentDisable = true
        break
      }
    }
  }
  return { allRentDisable, splitRentDisable, upHouseFlag }
}

/**
 * 获取分租部位下分级出租部位
 * @param {Array} originRoomList 未处理的监管系统拆分原始数据，只有一份原始数据，可以对应多个分租部位（第四个参数）
 * @param {*} subHouseNum 拆分部位，做分租部位上架或者托管时，后台新增一条房源，此房源编号是没有变，新增了分租三项信息，
 * 此时对于整个房源备案来说是已经有分租上架或者备案，不能进行整租。
 * @param {String} iszz 是否转租，调用源使用sublet字段判断是否是转租
 * @param {Array} originUpRoomList 本系统已上架房源部位，存的是房源编号，可能传空数组
 * @returns
 */
const getRentBwSelectOptions = (
  originRoomList,
  subHouseNum,
  iszz,
  originUpRoomList
) => {
  // 临时数据
  const templist = []

  // 是否转租
  // const iszz = !![9, 12, 13, 14].find((item) => item === type);

  // 分租部位房屋编号
  if (subHouseNum) {
    handleListToTree(originRoomList, subHouseNum, templist)
  }
  const list = templist.length ? templist : originRoomList

  // 房源字段首节点
  let firstNodeList = null
  if (!subHouseNum) {
    firstNodeList = list.filter(
      (item) => item.parentRoomNum === '' || item.parentRoomNum === null
    )
  } else {
    firstNodeList = list
    // firstNodeList = list.filter((item) => item.roomNum === subHouseNum)
  }

  const result = []
  handleRoomToTree(
    firstNodeList,
    originRoomList,
    iszz,
    result,
    originUpRoomList
  )
  return result
}

const handleRoomToTree = (
  firstNodeList,
  originRoomList,
  iszz,
  result,
  originUpRoomList
) => {
  for (const firstNode of firstNodeList) {
    let isDisabled = false
    // 根据房源记录是否是分租
    if (!iszz) {
      // 是否已占用 occupancyType：0-"否", 1: "是"
      if (firstNode.occupancyType === '1' || firstNode.occupancyType === '2') {
        isDisabled = true
      }
    }
    // 是否已转租 subleaseType：0- "否", 1-"是"
    if (firstNode.subleaseType === '1') {
      isDisabled = true
    }

    if (firstNode.status === '1') {
      // 0: "正常", 1: "审核中"。审核中当前房间不可分租，其他兄弟房间可以分租
      isDisabled = true
    }

    // 查找parentNode名下的子节点
    const childrenNodes = originRoomList.filter(
      (node) => node.parentRoomNum === firstNode.roomNum
    )
    // 如果上架房源列表有该房间，则在展示
    let isUp = false
    if (originUpRoomList && originUpRoomList.length) {
      for (const upHouse of originUpRoomList) {
        if (iszz && upHouse.rentType === 1 && upHouse.sublet == 1) {
          isDisabled = true
          isUp = true
          break
        } else if (!iszz && upHouse.rentType === 1 && upHouse.sublet === 0) {
          isDisabled = true
          isUp = true
          break
        } else if (
          iszz &&
          upHouse.roomNum === firstNode.roomNum &&
          upHouse.sublet === 1
        ) {
          isDisabled = true
          isUp = true
          break
        } else if (
          !iszz &&
          upHouse.roomNum === firstNode.roomNum &&
          upHouse.sublet === 0
        ) {
          isDisabled = true
          isUp = true
          break
        }
      }
    }
    const option = {
      label: isUp
        ? firstNode.roomName + '(已上架)'
        : firstNode.roomName + '(' + firstNode.roomSpace + '㎡)',
      value: `${firstNode.roomNum}|${firstNode.roomSpace}|${firstNode.roomType}|${firstNode.roomName}`,
      disabled: isDisabled,
      children: []
    }

    result.push(option)

    if (childrenNodes.length) {
      handleRoomToTree(
        childrenNodes,
        originRoomList,
        iszz,
        option.children,
        originUpRoomList
      )
    } else {
      delete option.children
    }
  }
}

/**
 * 获取分租部位下分级出租部位
 * @param {Array} originRoomList 未处理的监管系统拆分原始数据，只有一份原始数据，可以对应多个分租部位（第四个参数）
 * @param {String} subHouseNum 拆分部位，做分租部位上架或者托管时，后台新增一条房源，此房源编号是没有变，新增了分租三项信息，
 * 此时对于整个房源备案来说是已经有分租上架或者备案，不能进行整租，
 * @param {Array} templist 存储结果信息
 * @param {Array} originUpRoomList 本系统已上架房源部位，存的是房源编号，可能传空数组
 */
const handleListToTree = (originRoomList, subHouseNum, templist) => {
  if (originRoomList.length && subHouseNum) {
    originRoomList.forEach((item) => {
      if (item.parentRoomNum === subHouseNum) {
        templist.push(item)
        handleListToTree(originRoomList, item.roomNum, templist)
      }
    })
  }
}

export { getRentSelectOptions, getRentBwSelectOptions, handleListToTree }

<!--新建拆分-->
<template>
  <el-dialog
    v-model='splitFormDialogVisible'
    :close-on-click-modal='false'
    title='房源拆分'
    width='960px'
    top='3vh'
  >
    <el-scrollbar height='590px'>
      <el-form
        :model='splitForm'
        label-width='120px'
        ref='splitFormRef'
        :rules='splitFormRules'
        inline
        style='padding-bottom: 10px'
      >
        <el-divider
          content-position='left'
          v-if='actionType === SPLIT_ACTION_EDIT'
        >
          变更房源列表
        </el-divider>
        <el-divider
          content-position='left'
          v-if='actionType === SPLIT_ACTION_ADD'
        >
          新增房屋拆分列表
        </el-divider>
        <el-divider
          content-position='left'
          v-if='actionType === SPLIT_ACTION_DEL'
        >
          注销拆分列表
        </el-divider>
        <el-button
          v-if='actionType === SPLIT_ACTION_ADD && statusInfoValue==null'
          type='primary'
          style='margin-bottom: 10px'
          @click='addSplitClick'
        >新增
        </el-button>
        <el-table
          ref='multipleTableRef'
          :data='tableData'
          border
          style='width: 100%'
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa'
          }"
          @selection-change='handleSelectionChange'
        >
          <el-table-column prop='roomNum' label='房源编号' />
          <el-table-column prop='roomName' label='房间号' />
          <el-table-column prop='roomSpace' label='房间面积(㎡)' />
          <el-table-column prop='roomType' label='房间类型' />
          <el-table-column label='操作' width='120'>
            <template #default='scope'>
              <el-button
                type='text'
                size='small'
                class='list-btn'
                @click='edit(scope.row)'
              >变更
              </el-button>
              <el-button
                type='text'
                size='small'
                class='list-btn'
                @click='del(scope.row, scope)'
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-divider content-position='left'
        >请上传房屋户型图(图片格式且必填，查看
          <el-popover placement='right' :width='450' trigger='hover'>
            <template #reference>
              <el-link type='primary' style='margin-bottom: 5px'
              >示例图
              </el-link>
            </template>
            <el-image
              style='width: 100%; height: 300px'
              src='https://pc.nnfwzl.com/rent-house/rent-platform-web/house-split.png'
              :fit='`fit`'
            />
          </el-popover>
          )
        </el-divider>
        <nesi-upload
          @handleFile='handleFile'
          @deleteFile='deleteFile'
          :list='splitForm.scans'
        ></nesi-upload>
        <el-divider content-position='left'
        >房源明细表(图片格式且必填，查看
          <el-popover placement='right' :width='450' trigger='hover'>
            <template #reference>
              <el-link type='primary' style='margin-bottom: 5px'
              >示例图
              </el-link>
            </template>
            <el-image
              style='width: 100%; height: 300px'
              src='https://pc.nnfwzl.com/rent-house/rent-platform-web/house-detail.png'
              :fit='`fit`'
            />
          </el-popover>
          )
        </el-divider>
        <nesi-upload
          @handleFile='fyScannerHandleFile'
          @deleteFile='fyScannerHeleteFile'
          :list='splitForm.fyScanner'
        ></nesi-upload>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='splitFormDialogVisible = false'>取消</el-button>
        <el-button type='primary' @click='submitAddForm' :loading='loading'
        >提交</el-button
        >
      </span>
    </template>
    <add-split
      ref='addSplitRef'
      @change='onTabelDataChange'
      :status-info='statusInfoValue'
      :add-room-list='tableData'
      :house-number='houseNumber'
    ></add-split>
  </el-dialog>
</template>

<script setup>
import NesiUpload from '@/components/NesiUpload/index.vue'
import { reactive, ref, defineExpose, inject, unref, defineEmits } from 'vue'
import AddSplit from './add-split.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  addSubleaseHouseApi,
  cancelSubleaseApply,
  changeSubleaseApi
} from '@/api/user-house'
import {
  SPLIT_ACTION_ADD,
  SPLIT_ACTION_EDIT,
  SPLIT_ACTION_DEL
} from '@/constant'

const actionType = ref(1)
const emit = defineEmits(['success'])
const house = inject('houseInfo', {})
const loading = ref(false)
const addSplitRef = ref()
const splitFormDialogVisible = ref(false)
const multipleTableRef = ref(null)
const multipleSelection = ref([])
// 查询拆分记录信息
const statusInfoValue = ref(null)
const houseNumber = ref('')
// const toggleSelection = (rows) => {
//   if (rows) {
//     rows.forEach((row) => {
//       multipleTableRef.value = toggleRowSelection(row, undefined)
//     })
//   } else {
//     multipleTableRef.value = clearSelection()
//   }
// }
const handleSelectionChange = (val) => {
  multipleSelection.value = val
}

const splitForm = reactive({
  location: '',
  fyScanner: [],
  scans: []
})

const validatorImage = (rule, value, callback) => {
  if (!splitForm.scans.length) {
    callback(new Error('请上传房源图片'))
  } else if (rule.minLength && splitForm.scans.length < rule.minLength) {
    callback(new Error('请上传至少3张房源图片'))
  } else {
    callback()
  }
}

const splitFormRules = reactive({
  location: [{ required: true, message: '小区名称不能为空', trigger: 'blur' }],
  houseImage: [
    {
      validator: validatorImage,
      trigger: 'blur',
      minLength: 3
    }
  ]
})
const tableData = ref([])
const onTabelDataChange = ({ isEdit = false, room = {} }) => {
  if (isEdit) {
    const result = tableData.value.map((item) => {
      if (item.id === room.id || item.roomNum === room.id) {
        item = {
          ...room
        }
      }
      return item
    })
    console.log(room, result)

    tableData.value = result
  } else {
    tableData.value.push(room)
  }
  // tableData.value = data
}
const edit = (row) => {
  addSplitRef.value.edit(row)
}
const addSplitClick = () => {
  addSplitRef.value.open()
}
const handleFile = (value) => {
  splitForm.scans.push({
    name: value.name,
    smjmc: value.name,
    smjsj: value.path,
    uid: value.uid,
    url: value.url
  })
}

const deleteFile = (value) => {
  splitForm.scans = splitForm.scans.filter((item) => item.uid !== value.uid)
}

const fyScannerHandleFile = (value) => {
  splitForm.fyScanner.push({
    name: value.name,
    smjmc: value.name,
    smjsj: value.path,
    uid: value.uid,
    url: value.url
  })
}

const fyScannerHeleteFile = (value) => {
  splitForm.fyScanner = splitForm.fyScanner.filter(
    (item) => item.uid !== value.uid
  )
}

const checkForm = () => {
  let error = null
  if (!splitForm.fyScanner.length) {
    error = '请先上传房源明细表图片'
  }
  if (!splitForm.scans.length) {
    error = '请先上传房屋户型图'
  }
  if (!tableData.value.length) {
    error = '新增拆分列表数据不能为空'
  }
  return error
}

const getPlayad = () => {
  const houseInfo = unref(house)

  let form = {
    houseNumber: houseInfo.houseNumber,
    buildingSpace: houseInfo.buildingArea,
    certNo: houseInfo.warrantNo,
    location: houseInfo.location,
    ywbh: '',
    scans: [...splitForm.scans, ...splitForm.fyScanner].map((item) => {
      return { smjmc: item.smjmc, smjsj: item.smjsj }
    }),
    type: 1,
    fjs: tableData.value
  }
  if (statusInfoValue.value) {
    form = {
      ...form,
      houseNumber: statusInfoValue.value.houseNum,
      buildingSpace: statusInfoValue.value.buildingSpace,
      certNo: statusInfoValue.value.certificateNo,
      location: statusInfoValue.value.location,
      applyFlag: 2, // 业务申请标识1：首次申请 ；2:业务回退后再申请
      businessNo: statusInfoValue.value.businseeNo
    }
  } else {
    form.applyFlag = 1
  }
  return form
}
/**
 * 提交表单数据
 */
const submitAddForm = async () => {
  const error = checkForm()
  if (error) {
    ElMessage.error(error)
    return
  }
  const playad = getPlayad()
  console.log(playad)
  try {
    loading.value = true
    if (actionType.value === SPLIT_ACTION_ADD) {
      /* 新建拆分 */
      await addSubleaseHouseApi(playad)
    } else {
      /* 变更或者注销业务 */
      /* const businessNoCheck = tableData.value.filter(
        (item) => item.businessNo != null && item.businessNo != ''
      )
      playad.applyFlag = businessNoCheck.length ? 2 : 1 // 业务申请标识1：首次申请 ；2:业务回退后再申请
      playad.businessNo = businessNoCheck.length
        ? businessNoCheck[0].businessNo
        : ''
      if (statusInfoValue.value) {
        playad.applyFlag = 2
        playad.businessNo = statusInfoValue.value.businseeNo
      } */
      playad.fjs = tableData.value.map((item) => {
        // 变更标志0：房间变更1：房间注销
        if (!item.changeFlag) {
          item.changeFlag = actionType.value === SPLIT_ACTION_EDIT ? 0 : 1
        }
        return item
      })
      console.log(playad)
      await changeSubleaseApi(playad)
    }
    emit('success', { type: actionType.value })
    splitFormDialogVisible.value = false
  } finally {
    loading.value = false
  }
}
/**
 *
 * @param rows
 * @param type
 * @param statusInfo 查询状态页面变更材料传递的业务数据
 */
const open = ({ rows = [], type = SPLIT_ACTION_ADD, statusInfo = null, minioUrl = '' }) => {
  splitFormDialogVisible.value = true
  actionType.value = type
  splitForm.scans = []
  splitForm.fyScanner = []
  if (statusInfo) {
    statusInfoValue.value = statusInfo
    houseNumber.value = statusInfo.houseNum
    initStatusForm(statusInfo, minioUrl)
  } else {
    statusInfoValue.value = null
    houseNumber.value = ''
  }
  if (rows && rows.length) {
    tableData.value = [...rows]
  } else {
    tableData.value = []
  }
}

const initStatusForm = (statusInfo, minioUrl) => {
  const images = JSON.parse(statusInfo.imagePath)
  for (let index in images) {
    index = parseInt(index)
    const imageItem = images[index]
    let imageUrl = ''
    if (typeof (imageItem) === 'string') {
      imageUrl = imageItem
    } else {
      imageUrl = imageItem.smjsj
    }
    var number = imageUrl.lastIndexOf('/')
    var filename = ''
    if (number != -1) {
      number = number + 1
      filename = imageUrl.substring(number)
    } else {
      filename = Date.now().toString(36)
    }
    const img = {
      name: filename,
      smjmc: filename,
      smjsj: imageUrl,
      uid: filename,
      url: minioUrl + imageUrl
    }
    if (index % 2 === 0) {
      splitForm.scans = [...splitForm.scans, { ...img }]
    } else {
      splitForm.fyScanner = [...splitForm.fyScanner, { ...img }]
    }
  }
  console.log(splitForm.scans)
}

const del = (row, scope) => {
  ElMessageBox.confirm('是否删除该数据？', '操作提示', {
    confirmButtonText: '确认删除',
    cancelButtonText: '关闭',
    type: 'warning'
  }).then(() => {
    const index = scope.$index
    tableData.value = tableData.value.filter((item, i) => {
      return i != index
    })
  })
}
defineExpose({
  open
})
</script>

<style scoped></style>
